import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { muiStyles } from "../../Styles/muiStyles";
import flag from "../../Assets/flag.png";
import hamburgerIcon from "../../Assets/navbar-menu.svg";
import { getSessionStorage, setSessionStorage } from "../../Services/patients";

const PrimaryNavbar = ({ setOpenSignUpModel }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  // const language = getSessionStorage("language");
  const [language, setLanguage] = useState(
    getSessionStorage("language") || "English"
  );
  const handleLanguageChange = (event) => {
    event.preventDefault();

    setLanguage(event.target.value);
    setSessionStorage("language", event.target.value);

    window.location.reload();
  };

  return (
    <div className="primary-navbar">
      <div className="nav-right-section">
        {/* <FormControl
          variant="standard"
          sx={{
            border: "none",
          }}
        >
          <Select
            value={language}
            onChange={handleLanguageChange}
            className="select-lang"
            sx={{
              minWidth: "10px",
              fontFamily: "Proza Display",
              fontSize: "17px",
              "& input": {
                border: "none",
              },
            }}
          >
            <MenuItem
              value={"Hindi"}
              sx={{ fontFamily: "Proza Display", fontSize: "17px" }}
            >
              हिंदी
            </MenuItem>
            <MenuItem
              value={"English"}
              sx={{ fontFamily: "Proza Display", fontSize: "17px" }}
            >
              English
            </MenuItem>
          </Select>
        </FormControl> */}
        <a href="#about-us">
          {language === "Hindi" ? "हमारे बारे में" : "About Us"}
        </a>
        <a href="#getting-started">
          {language === "Hindi" ? "यह काम किस प्रकार करता है" : "How it Works"}
        </a>
        {/* <a href="#pricing">
          {language === "Hindi" ? "मूल्य निर्धारण" : "Pricing"}
        </a> */}
        <a href="#faq">{language === "Hindi" ? "सामान्य प्रश्न" : "FAQs"}</a>
        <a href="#contact-us">
          {language === "Hindi" ? "संपर्क करें" : "Contact Us"}
        </a>
        <Button
          variant="contained"
          sx={{
            ...muiStyles.primaryContainedButton,
            fontFamily: "Proza Display",
            fontSize: "15px",
          }}
          onClick={() => setOpenSignUpModel(true)}
        >
          {language === "Hindi" ? "साइन अप" : "Signup"}
        </Button>
      </div>
      {!isSideNavOpen && (
        <div className="navbar-hamburger-menu">
          <img
            src={hamburgerIcon}
            alt="navbar icon"
            className="hamburger"
            onClick={() => setIsSideNavOpen(true)}
          />
        </div>
      )}
      {isSideNavOpen && (
        <nav className="slider-nav">
          <img
            src={hamburgerIcon}
            alt="navbar icon"
            className="hamburger"
            onClick={() => setIsSideNavOpen(false)}
          />
          <div className="slider-nav-links">
            <FormControl
              variant="standard"
              sx={{
                border: "none",
              }}
            >
              <Select
                value={language}
                onChange={handleLanguageChange}
                className="select-lang"
                sx={{
                  minWidth: "10px",
                  fontFamily: "Proza Display",
                  fontSize: "19px",
                  "& input": {
                    border: "none",
                  },
                }}
              >
                <MenuItem
                  value={"Hindi"}
                  sx={{ fontFamily: "Proza Display", fontSize: "19px" }}
                >
                  हिंदी
                </MenuItem>
                <MenuItem
                  value={"English"}
                  sx={{ fontFamily: "Proza Display", fontSize: "19px" }}
                >
                  English
                </MenuItem>
              </Select>
            </FormControl>
            <a href="#about-us">
              {language === "Hindi" ? "हमारे बारे में" : "About Us"}
            </a>
            <a href="#getting-started">
              {language === "Hindi"
                ? "यह काम किस प्रकार करता है"
                : "How it Works"}
            </a>
            {/* <a href="#pricing">
              {language === "Hindi" ? "मूल्य निर्धारण" : "Pricing"}
            </a> */}
            <a href="#faq">{language === "Hindi" ? "सामान्य प्रश्न" : "FAQs"}</a>
            <a href="#contact-us">
              {language === "Hindi" ? "संपर्क करें" : "Contact Us"}
            </a>
            <Button
              variant="contained"
              sx={{
                ...muiStyles.primaryContainedButton,
                fontFamily: "Proza Display",
                fontSize: "15px",
              }}
              onClick={() => setOpenSignUpModel(true)}
            >
              {language === "Hindi" ? "साइन अप" : "Signup"}
            </Button>
          </div>
        </nav>
      )}
    </div>
  );
};

export default PrimaryNavbar;
