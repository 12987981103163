import React from "react";
import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
  let navigate = useNavigate();

  //   useLayoutEffect(() => {
  //     document.body.classList.add("pre_login");
  //     let userData = sessionStorage.getItem("userData");
  //     if (!userData) {
  //       return navigate("/patient/login");
  //     }
  //   }, []);

  const onGoback = (e) => {
    e.preventDefault();
    return navigate("/patient/register");
  };

  return (
    <div
      className="consent-sec"
      style={{ minHeight: "100vh", margin: "0", padding: "5vw" }}
    >
      <div className="align-self-center consent-para">
        <h4>Terms and Conditions</h4>
        <p>
          This Terms and Conditions governs your use of this website
          www.oncophr.com (the "Platform") with effect from the first date of
          your use of this Platform. This Terms and Conditions of Use (the
          "Terms and Conditions") represent a legal agreement between OncoPHR
          (“Company*” “Our”, “We”, “Us”) and an individual member ("you",
          "your", “yourself”, “User”).
        </p>
        <p>
          This Terms and Conditions are formulated and published pursuant to the
          provisions of Rule 3 (1) of the Information Technology (Intermediaries
          Guidelines) Rules, 2011 that requires the publication of Terms of Use
          and Privacy Policy for access or usage of a website.
        </p>
        <p>
          The Company reserves the right to modify the terms contained in this
          Agreement at any time by posting such modifications on the platform.
          The modified Agreement would be effective from the date of
          modification that is posted by the Company on the platform. If you do
          not agree to any term that is contained in this Agreement, please
          immediately cease your use of the Platform.
        </p>

        <p>
          <b>1. Users of the Platform</b>
        </p>
        <p>
          'User' of the Platform refers to any individual or entity who utilizes
          the Platform's services to save, access and share medical records in
          text, files, images, photos, video, or any other format. This includes
          but is not limited to:
          <ol type="a">
            <li>
              Patients: Individuals who wish to save their own medical records
              or grant access to their records to others.
            </li>
            <li>
              Relatives/Caregivers: Individuals who are authorized by the
              patient to access and manage their medical records on their
              behalf.
            </li>
            <li>
              Doctors: Healthcare professionals who wish to save medical records
              of their patients or access records of patients they are treating.
            </li>
            <li>
              Others: 'User' can also include hospitals, clinics or other
              healthcare facilities that wish to save and access medical records
              of their patients.
            </li>
          </ol>
        </p>

        <p>
          <b>2. Use of the Platform</b>
        </p>
        <p>
          The Users may freely browse through the Platform. However in order to
          avail services from the platform, Users are required to create a
          profile (“User Profile”) and register on the Platform. The
          registration and creation of a User Profile requires you to reveal
          your identity and/or other information about yourself, which will be
          governed by the Platform's Privacy Policy. In order to complete your
          User Profile, you will be required to inter alia provide your full
          name, email address, gender, mobile number(s), date of birth, current
          city/place of residence etc. Doctors as Users will be required to
          provide their specialization, qualification, area of practice,
          experience etc. Other Users will be required to provide the details of
          their registration details.
        </p>
        <p>
          Users may use the Platform to manage their medical records and create
          a digital summary of their medical history. The Platform also
          facilitates the communication between the Users at their choice.
          Through the Platform, the Patients can Communicate and share their
          medical records with Doctors, other Users and any third parties
          through e-mail and Doctors can communicate and share records with
          Patients and other Doctors and third parties through e-mail.
        </p>

        <p>
          Through the platform, the doctors as users can provide consultation
          services to their patients who are also the users of the Platform.
          Patients as users of the Platform can avail consultation from the
          doctors who are Users of the Platform. (Consultation Services)
        </p>
        <p>
          Use of the Platform is subject to subscription fees based on the plans
          chosen by the User. Subscription fees are due at the time of sign-up
          and on a recurring basis for the duration of the subscription.Users
          may choose to upgrade or downgrade their subscription plan at any
          time, subject to the terms and conditions of the new plan.Failure to
          pay subscription fees may result in the suspension or termination of
          User's access to the Platform.
        </p>

        <p>
          <b>3. Information Service, Not a Medical Advice</b>
        </p>
        <p>
          OncoPHR may provide information to you as part of its services. The
          information should never be taken as medical advice and should not
          relied solely as a guidance for healthcare. OncoPHR does not offer
          medical guidance, provide healthcare and does not represent the
          practice of medicine, nursing, or any other healthcare profession.
          OncoPHR does not diagnose, prescribe medication, treat or give
          instructions and does not hold ultimate control over your healthcare.
          Any information provided by us and through the platform are for
          educational and informational purposes only.
        </p>

        <p>
          <b>4. User Eligibility</b>
        </p>
        <p>
          The Platform is only available to individuals whose age is 18 years or
          above. Participants under the age of 18 are not allowed to use the
          platform. By agreeing to this Terms and Conditions, you confirm that
          you are 18 years of age or above, capable of understanding and
          following the rules outlined herein.
        </p>

        <p>
          <b>5. Privacy</b>
        </p>
        <p>
          OncoPHR values your privacy and the personal information you provide.
          In order to provide efficient services to you, OncoPHR may need to
          collect, use, and disclose your personal information according to this
          Terms and Conditions and our{" "}
          <a href="/patient/privacy-policy" target={"_blank"}>
            {" "}
            Privacy Policy
          </a>
          . By accepting this Terms and Conditions, you give permission for
          OncoPHR and our officers, directors, employees, contractors,
          retainers, interns, agents, affiliates and third-party service
          providers, up on your consent, to use and disclose your personal
          information in line with this Terms and Conditions and the{" "}
          <a href="/patient/privacy-policy" target={"_blank"}>
            {" "}
            Privacy Policy
          </a>
          , and for the purpose of rendering services to you.
        </p>

        <p>
          <b>6. Term and Termination</b>
        </p>
        <p>
          The term of this Terms and Conditions will commence on the date which
          you start using the platform, unless earlier terminated either by you
          or OncoPHR. You have the right to terminate this Terms and Conditions
          and withdraw yourself from using the platform and from availing its
          services at any time. To withdraw your acceptance from availing the
          services provided by the platform, you must contact OncoPHR by sending
          an email to{" "}
          <a href="mailto:contact@tatvahealth.com">contact@tatvahealth.com</a>.
          Your acceptance to the Terms and Conditions will terminate within
          thirty (30) days after OncoPHR receives your cancellation request and
          the termination will be acknowledged by OncoPHR through e-mail.
          OncoPHR reserves the right to terminate this Terms and Conditions and
          your access to the platform if you have provided false or misleading
          information or violated this Terms and Conditions. OncoPHR may, with
          notice and in its sole discretion, forthwith terminate your access and
          block or prevent your future access to the platform in case you breach
          or violate any terms of this Terms and Conditions or the Privacy
          Policy or in case OncoPHR learns or reasonably suspects you to be
          ineligible to access the platform and/or avail of any services
          provided by the platform in the course thereof
        </p>

        <p>
          <b>7. Representation by the User</b>
        </p>
        <p>
          By using the Platform, you guarantee that you have the legal right to
          do so and your participation does not breach any applicable laws,
          regulations, or intellectual or proprietary rights of others,
          including privacy and confidentiality rights.
        </p>
        <p>
          You agree that in the usage of the services provided by the Platform,
          the Platform may have access to the Users’ medical records including,
          but not limited to the Users’ diagnostics, lab reports and doctor
          prescriptions.
        </p>
        <p>
          You agree not to use the Platform for any purpose that is unlawful
          under any applicable laws and/or in violation of this Terms of Use and
          our Privacy Policy.
        </p>
        <p>
          You acknowledge that any actions taken on the Platform from your
          device will be considered as initiated by you.
        </p>
        <p>
          You agree to request OncoPHR to disable your account and change the
          password for the account, if your device, which is signed into your
          platform account, is lost or stolen. OncoPHR will not be held liable
          for any losses incurred by you or your organization due to your
          negligence or failure to report the loss or theft of your device
          and/or any unauthorized access to your account.
        </p>
        <p>
          You agree to take responsibility for all activities that occur in your
          account, and inform OncoPHR promptly if there is an unauthorized use
          of your account or any security breach.You will be held accountable
          for any losses incurred by OncoPHR or any third party due to the
          misuse of your account by a third party.
        </p>
        <p>
          You agree not to use another User's account without their express
          written permission and OncoPHR's consent.
        </p>
        <p>
          You are obligated to comply with all applicable laws, rules, and
          regulations while using the platform's features and functions. OncoPHR
          will not be liable for any default by you.
        </p>
        <p>
          The platform may contain links to third-party websites, affiliates,
          and business partners, and OncoPHR has no control over the content,
          accuracy, validity, reliability, or quality of such websites. OncoPHR
          is not liable or responsible for any information made available
          through such links. Inclusion of any link on the platform does not
          imply endorsement by OncoPHR, and any use of such links or services is
          at the user's own risk.
        </p>
        <p>
          You shall, directly or indirectly through third parties, not: (a) make
          and/or distribute copies of the Platform; (b) attempt to copy,
          reproduce, alter, modify and/or reverse engineer the Platform; and/or
          (c) create derivative works of the Platform.
        </p>
        <p>
          Without prior written consent from the respective owner, you are not
          permitted to distribute, reproduce, modify, or post any copyrighted
          material, trademarks, or other proprietary information belonging to
          OncoPHR, Third Party Service Provider, or others (including unlicensed
          third-party content).
        </p>
        <p>
          You agree not to use the Platform in any manner that could damage,
          disable, overburden, or impair any OncoPHR server, any network(s)
          connected to any OncoPHR server, or interfere with any other party's
          use and enjoyment of the Platform. You shall not attempt to gain
          unauthorized access to any functions and features, other User
          accounts, computer systems or networks connected to any server, in any
          manner, including, through hacking, password mining or any other
          unlawful means. You shall not obtain or attempt to obtain any
          materials or information through any means which is not intentionally
          made available through the Platform.
        </p>
        <p>
          You agree that the services provided are on an "as is" and "as
          available" basis without any warranty of any kind, whether express,
          implied, statutory, or otherwise. The user agrees that the use of the
          services is at their sole risk. The Platform, the Company, its
          subsidiary, affiliate, director, officer, employee, or agent expressly
          disclaims any and all implied or express warranties to the fullest
          extent permitted by applicable law concerning the services. Any advice
          or information, whether oral or written, obtained by the user from the
          Platform or services shall not create any warranty not expressly
          stated in the terms of use.
        </p>
        <p>
          You agree to indemnify, defend and hold harmless OncoPHR, its
          officers, directors, employees, contractors, retainers, interns,
          agents, affiliates and third-party service providers from and against
          all losses, damages, expenses, harm, injury and/or death which you, or
          any third party may suffer, howsoever arising, in connection with or
          resulting from your violation of this Terms and Conditions and/or your
          use of the platform and/or your infringement of any intellectual
          property or other right of OncoPHR.
        </p>

        <p>
          <b>8. Disclaimer of Warranty</b>
        </p>
        <p>
          The information and services provided by OncoPHR are given to you on
          ‘as is’ basis, without any warranties of any kind. The information
          provided is not a medical advice or to be relied on for medical
          diagnosis or treatment at anytime. It is only intended for
          informational and consultative purposes.{" "}
        </p>
        <p>
          The Platform is merely a facilitator storing information provided by
          the Users and does not in any manner alter the information uploaded on
          to the Platform by the Users.
        </p>
        <p>
          The services in the Platform may be subject to certain limitations,
          such as, limits on disk storage space and other limitations. The
          Platform reserves the right to deactivate the User account in case of
          any foul, abusive, inappropriate language used in any functionality
          including reviews, comments, interaction with the Platform or other
          Users.
        </p>
        <p>
          The Platform make no guarantees, representations or warranties,
          whether expressed or implied, with respect to professional
          qualifications, quality of work/advice or expertise of any Doctor
          providing Consultation Services and shall in no event be liable for
          any claims pertaining to malpractice, negligence, deficiency of
          services on the part of the Doctor.
        </p>
        <p>
          Any prescriptions made by the Doctors basis the Consultation Services
          shall be at sole discretion of the Doctor and such Doctor shall be
          solely responsible for making such prescription. OncoPHR does not
          endorse any prescription nor does it deal in medical drugs.
        </p>
        <p>
          By availing Consultation Services, User provides its consent to
          OncoPHR for recoding, saving and storing for record purposes all such
          Consultation Services availed through the Platform by User in the
          event such records are required to be produced as evidence on the
          direction of a court of law. OncoPHR may review such data from time to
          time for quality evaluation purposes. However, under normal
          circumstances, OncoPHR will not access any information that identifies
          a User and shall either omit, mask or anonymise User's personal
          information at the time of quality evaluation.
        </p>
        <p>
          OncoPHR and its affiliates, including licensors, partners,
          consultants, suppliers, employees, and agents, do not guarantee the
          accuracy, reliability, usefulness, completeness, or timeliness of the
          information provided, and they explicitly reject all warranties,
          expressed or implied, statutory or otherwise, including but not
          limited to warranties of merchantability, non-infringement, and
          fitness for a particular purpose.
        </p>

        <p>
          <b>9. Limitation of Liability</b>
        </p>
        <p>
          OncoPHR in no event is liable for any losses or injuries suffered by
          the Users or arising out of any actions or omissions made by the Users
          or any other person relying on the information provided on the
          Platform.{" "}
        </p>
        <p>
          OncoPHR make no guarantees, representations or warranties, whether
          expressed or implied, with respect to professional qualifications,
          quality of work/advice or expertise of the Doctors providing
          Consultation Services and shall in no event be liable for any claims
          pertaining to malpractice, negligence, deficiency of services on the
          part of the Doctors.
        </p>
        <p>
          OncoPHR and its affiliates, partners, consultants, suppliers,
          employees, and agents will not be held responsible for any indirect,
          special, consequential, or punitive damages arising from any cause,
          including but not limited to, health issues, loss of profits, delays,
          business interruptions, lost data or documentation, or third-party
          liabilities. This limitation applies regardless of the basis of the
          claim, whether it be warranty, contract, tort, or any other legal
          theory, and whether or not OncoPHR has been informed of the potential
          damages. By agreeing to this Terms and conditions, you voluntarily
          assume any risks associated with using the platform and release
          OncoPHR from any potential injury, including bodily injury, and any
          claims due to intentional misconduct, negligence, or gross negligence.
          You hereby acknowledge your full understanding and willingness to
          accept any risks associated with your use of the platform and waive
          OncoPHR from liability in relation to such use.
        </p>

        <p>
          <b>10. Proprietary Rights</b>
        </p>
        <p>
          OncoPHR, along with its affiliates and licensors, holds complete
          rights, ownership, and interests in the Platform and its components,
          including all intellectual property rights in materials such as
          opinions, text, graphics, logos, button icons, images, audio and video
          clips, and software. this Terms and conditions do not restrict,
          transfer, convey, encumber, alter, impair, or negatively impact
          OncoPHR's, its affiliates', and/or their licensors' ownership or
          proprietary rights in the Platform or any other of their information,
          processes, methodologies, products, goods, services, trademarks, or
          materials, in any form or medium.
        </p>

        <p>
          <b>11. Change in Features</b>
        </p>
        <p>
          OncoPHR, its affiliates, and content providers reserve the right to
          modify, update, supplement, limit, discontinue, remove or disable
          access to the Platform and/or any services or features thereof without
          notice to you and neither OncoPHR, its affiliates, clients nor content
          providers nor any of their respective licensors shall be liable to you
          or any third party for exercising such rights.
        </p>

        <p>
          <b>12. Governing Law</b>
        </p>
        <p>
          This Terms and Conditions and the Platform, including any content and
          information contained therein shall be governed by the laws of the
          Republic of India. You consent to the jurisdiction of the courts of
          Bengaluru, Karnataka, India, with respect to any proceedings arising
          out of this Terms and Conditions, provided that OncoPHR shall be
          entitled to institute such proceedings in any other appropriate venue
          if it so chooses.
        </p>

        <p>
          <b>13. Territorial Restriction</b>
        </p>
        <p>
          The Platform is made available for the users from/in India. If you are
          a user accessing the Platform from the United States of America,
          European Union, or any other region with laws or regulations governing
          personal data collection, use, and disclosure, that differ from Indian
          laws, please be advised that through your continued use of the
          Platform, which is governed by Indian law, you are transferring your
          personal information to India and you consent to that transfer and to
          the collection and usage of your personal information in accordance
          with the laws of the Republic of India.
        </p>
        <p>
          * The Company includes the owner of the Platform, Tatva Health, having
          its registered office at 300 Delaware Avenue, Suite 210 #532,
          Wilmington, DE, 19801, its affiliates and licensees.
        </p>

        {/* <div className="row">
          <div
            className="col-sm-12 text-center"
            style={{ textAlign: "center", display: "block" }}
          >
            <button className="doc-back" onClick={(e) => onGoback(e)}>
              Go Back
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default TermsAndConditions;
