import { Typography } from "@mui/material";
import React, { useState } from "react";
import { muiStyles } from "../../Styles/muiStyles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const FAQs = ({ language }) => {
  // const [clicked, setClicked] = useState(false);
  const [openQuestions, setOpenQuestions] = useState([]);

  const QA = [
    {
      id: 1,
      ques:
        language === "Hindi"
          ? "क्या OncoPHR का उपयोग करने में कोई लागत शामिल है?"
          : "Is there any cost involved in using OncoPHR?",
      ans:
        language === "Hindi"
          ? "यदि आपके पास एक्सेस कोड है, तो OncoPHR पूरी तरह से मुक्त है। लेकिन, यदि आपको एक्सेस कोड नहीं मिला है, तो प्लेटफ़ॉर्म का उपयोग करने के लिए सदस्यता शुल्क लगता है।"
          : "If you have an Access Code, OncoPHR is completely FREE of cost. But, if you haven't received an access code, there's a membership fee to use the platform.",
    },
    {
      id: 2,
      ques:
        language === "Hindi"
          ? "मैं एक्सेस कोड कैसे प्राप्त कर सकता हूं?"
          : "How can I get an Access Code?",
      ans:
        language === "Hindi"
          ? "कृपया +1 650 420 9271 पर हमसे संपर्क करें या अपने फ़ोन नंबर के साथ contact@tatvahealth.com  पर एक ईमेल भेजें और हम आपसे संपर्क करेंगे।"
          : "Please reach out to us at +1 650 420 9271 or send an email to contact@tatvahealth.com with your phone number and we will contact you.",
    },
    {
      id: 3,
      ques:
        language === "Hindi"
          ? "क्या मैं बिना एक्सेस कोड के OncoPHR के लिए साइन-अप कर सकता हूं?"
          : "Can I Sign-Up for OncoPHR without an Access Code?",
      ans:
        language === "Hindi"
          ? "हां। कृपया +1 650 420 9271 पर हमसे संपर्क करें या अपने फ़ोन नंबर के साथ contact@tatvahealth.com पर एक ईमेल भेजें और हम आपसे संपर्क करेंगे।"
          : "Yes. Please reach out to us at +1 650 420 9271 or send an email to contact@tatvahealth.com with your phone number and we will contact you.",
    },
    {
      id: 4,
      ques:
        language === "Hindi"
          ? "सदस्यता शुल्क क्या है?"
          : "How much is the Membership Fee?",
      ans:
        language === "Hindi"
          ? "बिना एक्सेस कोड वाले लोगों के लिए सदस्यता शुल्क 5000 रुपये है। हम उन लोगों को समर्थन देने के लिए वैकल्पिक विकल्प ढूंढेंगे जो मामला-दर-मामला आधार पर भुगतान नहीं कर सकते। +1 650 420 9271 पर हमसे संपर्क करें या समर्थन के लिए एक ईमेल भेजें @oncophr.com."
          : "There is a first year annual membership fee of USD 1250 per annum for those without an Access Code. We will find alternate options to support people who cannot afford on a case-by-case basis. Contact us at +1 650 420 9271 or send an email to contact@tatvahealth.com.",
    },
    {
      id: 5,
      ques:
        language === "Hindi"
          ? "यदि मैं चाहूँ तो क्या मैं अपना OncoPHR खाता हटा सकता हूँ?"
          : "Can I delete my OncoPHR account if I want to?",
      ans:
        language === "Hindi"
          ? "हां। आपके रिकॉर्ड आपके अपने हैं, और आपका पूरा नियंत्रण आपके पास है। हटाने का अनुरोध करने के लिए बस contact@tatvahealth.com पर एक ईमेल भेजें। एक बार सत्यापित होने के बाद, हम आपके खाते और आपके सभी रिकॉर्ड को स्थायी रूप से हटा देंगे।"
          : "Yes. Your records are your own, and you have full control. Just send an email to contact@tatvahealth.com to request deletion. Once verified, we'll permanently remove your account and all your records.",
    },
    {
      id: 6,
      ques:
        language === "Hindi"
          ? "यदि मैं अपनी OncoPHR सदस्यता वापस लेने का विकल्प चुनता हूं तो मेरे रिकॉर्ड का क्या होगा?"
          : "What happens to my records if I choose to withdraw my OncoPHR membership?",
      ans:
        language === "Hindi"
          ? "आपका विलोपन अनुरोध प्राप्त होने पर, हम पुष्टि करने के लिए आपसे संपर्क करेंगे। हम आपके रिकॉर्ड को गंभीरता से लेते हैं और नहीं चाहते कि आप अनजाने में पहुंच खो दें। एक बार सत्यापित होने के बाद, हम आपके सभी रिकॉर्ड हमारे डेटाबेस से हटा देंगे, और आपको सूचित कर दिया जाएगा।"
          : "Upon receiving your deletion request, we'll contact you to confirm. We take your records seriously and don't want you to lose access unintentionally. Once verified, we'll delete all your records from our databases, and you'll be notified.",
    },
    {
      id: 7,
      ques:
        language === "Hindi"
          ? "यदि मैं सदस्यता के लिए भुगतान करने के बाद वापस लेने का निर्णय लेता हूं तो क्या मुझे रिफंड मिलेगा?"
          : "Will I get a refund if I decide to withdraw after making a payment for membership?",
      ans:
        language === "Hindi"
          ? "हां। हम यथानुपात रिफंड जारी करेंगे। कृपया हमसे +1 650 420 9271 पर संपर्क करें या स्पष्टीकरण के लिए contact@tatvahealth.com पर एक ईमेल भेजें।"
          : "Yes. We'll issue a pro-rata refund. Please contact us at +1 650 420 9271 or send an email to contact@tatvahealth.com for clarification.",
    },
  ];

  const toggle = (index) => {
    setOpenQuestions((prev) => {
      const newOpenQuestions = [...prev];
      newOpenQuestions[index] = !newOpenQuestions[index];
      return newOpenQuestions;
    });
  };
  return (
    <div className="faq-container">
      <Typography
        textAlign="center"
        sx={{ ...muiStyles.secondaryText, fontSize: "24px" }}
      >
        {language === "Hindi" ? "सामान्य प्रश्न" : "Frequently Asked Questions"}
      </Typography>
      {QA.map((item, index) => {
        return (
          <div className="question" key={item.id} onClick={() => toggle(index)}>
            <div className="question-header">
              <Typography sx={{ fontWeight: "600" }}>{item.ques}</Typography>
              {openQuestions[index] ? <ExpandLess /> : <ExpandMore />}
            </div>
            {openQuestions[index] && <div className="answer">{item.ans}</div>}
          </div>
        );
      })}
    </div>
  );
};

export default FAQs;
