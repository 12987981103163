import {
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import "../../Styles/styles2.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  register,
  getSessionStorage,
  listCancers,
} from "../../Services/patients";
import { muiStyles } from "../../Styles/muiStyles";
import { HighlightOff } from "@mui/icons-material";

const SignUpModal = ({ setOpenSignUpModel }) => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  // ========================================
  const [values, setValues] = useState({
    phone_number: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    cpassword: "",
    // date_of_birth: "",
    // cancer_type: "",
    gender: "",
    abha: "",
    is_tnc: false,
    is_privacy: false,
    is_consent: false,
    primary_doctor: "",
  });
  const [cancersList, setCancersList] = useState([]);
  const [errors, setErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const language = getSessionStorage("language");

  useEffect(() => {
    document.body.classList.add("pre_login");
    loadCancerList();
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("phone_number" in fieldValues)
      temp.phone_number =
        fieldValues.phone_number.length > 9
          ? ""
          : language === "Hindi"
          ? "कृपया एक वैध फ़ोन नंबर दर्ज करें"
          : "Please enter a valid phone number";
    if ("first_name" in fieldValues)
      temp.first_name = /[A-Za-z0-9]+/.test(fieldValues.first_name)
        ? ""
        : language === "Hindi"
        ? "पहला नाम खाली नहीं हो सकता"
        : "First Name cannot be empty";
    if ("last_name" in fieldValues)
      temp.last_name = /[A-Za-z0-9]+/.test(fieldValues.last_name)
        ? ""
        : language === "Hindi"
        ? "अंतिम नाम खाली नहीं हो सकता"
        : "Last Name cannot be empty";
    // if ("gender" in fieldValues)
    //   temp.gender = /[A-Za-z0-9]+/.test(fieldValues.gender)
    //     ? ""
    //     : language === "Hindi"
    //     ? "लिंग खाली नहीं हो सकता"
    //     : "Gender cannot be empty";
    // if ("cancer_type" in fieldValues)
    //   temp.cancer_type = /[A-Za-z0-9]+/.test(fieldValues.cancer_type)
    //     ? ""
    //     : language === "Hindi"
    //     ? "कैंसर के प्रकार खाली नहीं हो सकते"
    //     : "Cancer type cannot be empty";
    if ("primary_doctor" in fieldValues)
      temp.primary_doctor = /[A-Za-z0-9]+/.test(fieldValues.primary_doctor)
        ? ""
        : language === "Hindi"
        ? "एक्सेस कोड खाली नहीं हो सकता"
        : "Access code cannot be empty";
    if ("abha" in fieldValues)
      temp.abha = !(
        fieldValues.abha.length > 0 && fieldValues.abha.length !== 14
      )
        ? ""
        : language === "Hindi"
        ? "पासवर्ड की लंबाई कम से कम 6 होनी चाहिए"
        : "Please enter a valid ABHA number or keep the field empty";
    // if ("date_of_birth" in fieldValues)
    //   temp.date_of_birth = /[A-Za-z0-9]+/.test(fieldValues.date_of_birth)
    //     ? ""
    //     : language === "Hindi"
    //     ? "जन्म तिथि खाली नहीं हो सकती"
    //     : "DOB cannot be empty";
    // if ("date_of_birth" in fieldValues)
    //   temp.date_of_birth =
    //     fieldValues.date_of_birth.length === 0 ||
    //     getAge(fieldValues.date_of_birth) >= 0
    //       ? temp.date_of_birth
    //       : language === "Hindi"
    //       ? "भविष्य की तारीख स्वीकार्य नहीं है"
    //       : "Future date not allowed";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : language === "Hindi"
        ? "कृपया एक वैध ईमेल दर्ज करें"
        : "Please enter a valid email";
    if ("email" in fieldValues)
      temp.email = /[A-Za-z0-9]+/.test(fieldValues.email)
        ? temp.email
        : language === "Hindi"
        ? "ईमेल खाली नहीं हो सकता"
        : "Email cannot be empty";
    if ("password" in fieldValues)
      temp.password =
        fieldValues.password.length > 5
          ? ""
          : language === "Hindi"
          ? "पासवर्ड की लंबाई कम से कम 6 होनी चाहिए"
          : "Password length should be at least 6 characters";
    if ("cpassword" in fieldValues)
      temp.cpassword =
        fieldValues.cpassword === values.password
          ? ""
          : language === "Hindi"
          ? "पासवर्ड के समान होना चाहिए"
          : "Must be same as password";
    if ("adminKey" in fieldValues)
      temp.adminKey = /[A-Za-z0-9]+/.test(fieldValues.adminKey)
        ? ""
        : language === "Hindi"
        ? "पहला नाम खाली नहीं हो सकता"
        : "First Name cannot be empty";

    setErrors({ ...temp });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  // get cancer list
  const loadCancerList = async () => {
    try {
      const cancers = await listCancers();
      setCancersList(cancers.data.data);
      // console.log(cancers.data.data, "cancer list");
    } catch (err) {
      // setobjflashMessage({ message: err.response.data.message, type: 'danger' });
      window.scrollTo(0, 0);
    }
  };

  // ========================================

  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setValues({
      ...values,
      [name]: value,
    });

    validate({ [name]: value });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (!values?.is_tnc) {
      swal({
        text:
          language === "Hindi"
            ? "आगे बढ़ने के लिए कृपया नियम और शर्तें पढ़ें और स्वीकार करें"
            : "Please read and accept the Terms and Conditions in order to move further",
        icon: "error",
      });
    } else if (!values?.is_privacy) {
      swal({
        text:
          language === "Hindi"
            ? "आगे बढ़ने के लिए कृपया गोपनीयता नीति पढ़ें और स्वीकार करें"
            : "Please read and accept the Privacy Policy in order to move further",
        icon: "error",
      });
    } else if (!values?.is_consent) {
      swal({
        text:
          language === "Hindi"
            ? "आगे बढ़ने के लिए कृपया अपनी सहमति दें कि आपके डेटा को संग्रहित किया जाए"
            : "Please provide you consent to store your data in order to move further",
        icon: "error",
      });
    } else {
      let temp_val = validate();
      let jsonData = {
        ...values,
        ["phone_number"]: parseInt(values.phone_number),
      };
      if (!temp_val)
        return swal({
          icon: "error",
          text:
            language === "Hindi"
              ? "गलत फ़ील्ड्स की जांच करें"
              : "Check for incorrect fields",
        });
      try {
        const { data } = await register(jsonData);
        if (data.error) {
          // alert("error")
          swal({ text: data.error.message, icon: "error" });
          // setError(data.error);
          throw data;
        } else {
          swal({
            text:
              language === "Hindi"
                ? "साइन अप सफल रहा! कृपया अपने ईमेल को सत्यापित करें"
                : "Sign up successful! kindly verify your email",
            icon: "success",
          });
          setOpenSignUpModel(false);
        }
      } catch (error) {
        // console.log(error);
        swal({ text: error.response.data.message, icon: "error" });
      }
    }
  };
  return (
    <Box sx={muiStyles.signUpModal}>
      <div className="signup-container">
        <div className="signup-header">
          <div></div>
          <Typography
            component="h5"
            variant="h5"
            sx={{
              textAlign: "center",
              ...muiStyles.secondaryText,
              fontWeight: "600",
              mb: "30px",
            }}
          >
            {language === "Hindi" ? "साइन अप" : "Signup"}
          </Typography>
          <IconButton
            sx={{ mb: "30px" }}
            onClick={() => {
              setOpenSignUpModel(false);
            }}
          >
            <HighlightOff />
          </IconButton>
        </div>
        <form type="submit">
          <div className="form-container">
            <div className="signup-form-container">
              <div className="signup-input-container">
                <label className="input-label2">
                  {language === "Hindi" ? "पहला नाम*" : "First Name*"}
                </label>
                <TextField
                  className="input2"
                  variant="standard"
                  type="text"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.first_name && {
                    error: true,
                    helperText: errors.first_name,
                  })}
                />
              </div>
              <div className="signup-input-container">
                <label className="input-label2">
                  {language === "Hindi" ? "अंतिम नाम*" : "Last Name*"}
                </label>
                <TextField
                  className="input2"
                  variant="standard"
                  type="text"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.last_name && {
                    error: true,
                    helperText: errors.last_name,
                  })}
                />
              </div>
            </div>
            <div className="signup-form-container">
              <div className="signup-input-container">
                <label className="input-label2">
                  {language === "Hindi" ? "फ़ोन नंबर*" : "Phone Number*"}
                </label>
                <TextField
                  className="input2"
                  variant="standard"
                  type="number"
                  name="phone_number"
                  value={values.phone_number}
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.phone_number && {
                    error: true,
                    helperText: errors.phone_number,
                  })}
                />
              </div>
              <div className="signup-input-container">
                <label className="input-label2">
                  {language === "Hindi" ? "ईमेल आईडी*" : "Email ID*"}
                </label>
                <TextField
                  className="input2"
                  variant="standard"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.email && {
                    error: true,
                    helperText: errors.email,
                  })}
                />
              </div>
            </div>
            {/* <div className="signup-form-container">
              <div className="signup-input-container">
                <label className="input-label2">
                  {language === "Hindi" ? "ABHA नंबर" : "ABHA No."}
                </label>
                <TextField
                  className="input2"
                  variant="standard"
                  type="text"
                  name="abha"
                  value={values.abha}
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.abha && {
                    error: true,
                    helperText: errors.abha,
                  })}
                />
              </div>
              <div className="signup-input-container">
                <label className="input-label2">
                  {language === "Hindi" ? "लिंग*" : "Gender*"}
                </label>
                <TextField
                  select
                  className="input2 select"
                  variant="standard"
                  type="date"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.gender && {
                    error: true,
                    helperText: errors.gender,
                  })}
                >
                  {["Male", "Female"].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>             */}
            <div className="signup-form-container">
              <div className="signup-input-container">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    ml: {
                      xs: "15px",
                      sm: "15px",
                      md: "0",
                    },
                  }}
                >
                  <label className="input-label2">
                    {" "}
                    {language === "Hindi" ? "एक्सेस कोड*" : "Access Code*"}
                  </label>
                  <Typography sx={{ fontSize: "14px", ml: "10px" }}>
                    Don't have code?{" "}
                    <span
                      style={{ color: "#6E326E" }}
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                    >
                      Contact us
                    </span>
                  </Typography>
                </Box>
                <TextField
                  className="input2"
                  variant="standard"
                  type="text"
                  name="primary_doctor"
                  sx={{
                    ml: {
                      xs: "15px",
                      sm: "15px",
                      md: "0",
                    },
                  }}
                  value={values.primary_doctor}
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.primary_doctor && {
                    error: true,
                    helperText: errors.primary_doctor,
                  })}
                ></TextField>
              </div>
              <div className="signup-input-container">
                <label className="input-label2">
                  {language === "Hindi" ? "ABHA नंबर" : "ABHA No."}
                </label>
                <TextField
                  className="input2"
                  variant="standard"
                  type="text"
                  name="abha"
                  value={values.abha}
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.abha && {
                    error: true,
                    helperText: errors.abha,
                  })}
                />
              </div>
              {/* <div className="signup-input-container">
                <label className="input-label2">
                  {language === "Hindi"
                    ? "कैंसर का प्रकार चुनें*"
                    : "Choose Cancer Type*"}
                </label>
                <TextField
                  select
                  className="input2 select"
                  variant="standard"
                  type="date"
                  name="cancer_type"
                  value={values.cancer_type}
                  // placeholder="Enter Cancer Type"
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.cancer_type && {
                    error: true,
                    helperText: errors.cancer_type,
                  })}
                >
                  {cancersList?.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.cancer_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div> */}
            </div>

            <div className="signup-form-container">
              <div className="signup-input-container">
                <label className="input-label2">
                  {language === "Hindi" ? "पासवर्ड बनाएं*" : "Create Password*"}
                </label>
                <TextField
                  className="input2"
                  variant="standard"
                  type="password"
                  name="password"
                  value={values.password}
                  //   placeholder={
                  //     language === "Hindi" ? "पासवर्ड दर्ज करें" : "Enter Password"
                  //   }
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.password && {
                    error: true,
                    helperText: errors.password,
                  })}
                />
              </div>
              <div className="signup-input-container">
                <label className="input-label2">
                  {language === "Hindi"
                    ? "पासवर्ड की पुष्टि करें*"
                    : "Confirm Password*"}
                </label>
                <TextField
                  className="input2"
                  variant="standard"
                  type="password"
                  name="cpassword"
                  value={values.cpassword}
                  //   placeholder={
                  //     language === "Hindi"
                  //       ? "पासवर्ड की पुष्टि करें"
                  //       : "Confirm Password"
                  //   }
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.cpassword && {
                    error: true,
                    helperText: errors.cpassword,
                  })}
                />
              </div>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.is_tnc}
                  name="is_tnc"
                  onClick={handleChange}
                  sx={{
                    color: "#30F2A9",
                    "&.Mui-checked": {
                      color: "#30F2A9",
                    },
                  }}
                />
              }
              label={
                <span>
                  {language === "Hindi" ? "मैंने" : "I have read the "}
                  <Link to="/patient/terms-and-conditions" target="_blank">
                    {language === "Hindi"
                      ? " नियम और शर्तें (Terms and Conditions)"
                      : "Terms and Conditions"}
                  </Link>
                  {language === "Hindi"
                    ? " पढ़ ली हैं और मैं उनसे सहमत हूं।"
                    : " and agree to them"}
                </span>
              }
              sx={{
                width: "100%",
                textAlign: "left",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.is_privacy}
                  name="is_privacy"
                  onClick={handleChange}
                  sx={{
                    color: "#30F2A9",
                    "&.Mui-checked": {
                      color: "#30F2A9",
                    },
                  }}
                />
              }
              label={
                <span>
                  {language === "Hindi" ? "मैंने " : "I have read the "}
                  <Link to="/patient/privacy-policy" target="_blank">
                    {language === "Hindi"
                      ? "गोपनीयता नीति (Privacy Policy)"
                      : "Privacy Policy"}
                  </Link>{" "}
                  {language === "Hindi"
                    ? "पढ़ ली है और इससे सहमत हूं।"
                    : "and agree to it"}
                </span>
              }
              sx={{
                width: "100%",
                textAlign: "left",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.is_consent}
                  name="is_consent"
                  onClick={handleChange}
                  sx={{
                    color: "#30F2A9",
                    "&.Mui-checked": {
                      color: "#30F2A9",
                    },
                  }}
                />
              }
              label={
                <span style={{ textAlign: "left" }}>
                  {language === "Hindi"
                    ? "मैं OncoPHR को अपने मेडिकल रिकॉर्ड को स्टोर करने, एक्सेस करने और उपयोग करने के लिए "
                    : "I provide "}
                  <Link to="/patient/consent" target="_blank">
                    {language === "Hindi" ? "सहमति (Consent) " : "Consent "}
                  </Link>
                  {language === "Hindi"
                    ? "प्रदान करता हूं।"
                    : "to OncoPHR to store, access and use my medical records"}
                </span>
              }
              sx={{
                width: "100%",
                textAlign: "left",
              }}
            />
            <div style={{ textAlign: "center" }}>
              <button
                className="submit-button secondary-button"
                onClick={(e) => formSubmitHandler(e)}
              >
                {language === "Hindi" ? "सबमिट करें" : "Submit"}
              </button>
            </div>
            <Typography
              sx={{ textAlign: "center", mt: "10px", fontSize: "15px" }}
            >
              {language === "Hindi"
                ? "क्या आपके पास पहले से एक खाता मौजूद है?"
                : "Already have an account?"}
              <span
                style={{ color: "#6E326E" }}
                onClick={() => setOpenSignUpModel(false)}
              >
                {language === "Hindi" ? " लॉग इन" : " Login"}
              </span>
            </Typography>
            <Typography
              sx={{ textAlign: "center", mt: "5px", fontSize: "15px" }}
            >
              {language === "Hindi"
                ? "यदि आपको साइन अप या लॉग इन करने में कोई परेशानी आती है, तो कृपया "
                : "If you face any trouble to sign up or log in, kindly send a mail to "}
              <a href="mailto:contact@tatvahealth.com" style={{ color: "#6E326E" }}>
                contact@tatvahealth.com
              </a>
              {language === "Hindi" &&
                " पर मेल भेजें या +1 650 420 9271 पर कॉल करे (समय: 10.00 am से 6.00 pm तक)"}
              {language !== "Hindi" &&
                " or contact us at +1 650 420 9271 (Timings: 10.00 am to 6.00 pm)"}
            </Typography>
          </div>
        </form>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">{"Contact Us"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {language === "Hindi"
              ? "यदि आपको साइन अप या लॉग इन करने में कोई परेशानी आती है, तो कृपया "
              : "If you face any trouble to sign up or log in, kindly send a mail to "}
            <a href="mailto:contact@tatvahealth.com">contact@tatvahealth.com</a>
            {language === "Hindi" &&
              " पर मेल भेजें या +1 650 420 9271 पर कॉल करे (समय: 10.00 am से 6.00 pm तक)"}
            {language !== "Hindi" &&
              " or contact us at +1 650 420 9271 (Timings: 10.00 am to 6.00 pm)"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SignUpModal;
